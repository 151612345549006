<template>
  <div class="locations-create">
    <ui-wizard
      :title="$t('locations.create.title')"
      :breadcrumbs="breadcrumbs"
      :is-saving="isSaving"
      @back="onBack"
      @on-complete="onComplete"
      @update:startIndex="updateIndex"
      :is-valid="!$v.$error"
    >
      <ui-wizard-step :title="$t('locations.create.step.client')" :before-change="checkClient">
        <ui-dropdown
          class="locations-create__input"
          id="locations-client"
          v-model="client"
          :options="brands"
          :placeholder="$t('locations.create.client.placeholder')"
          :dropdown-label="$t('locations.create.client.label')"
          label="name"
          track-by="id"
          :show-label="true"
          :error="$v.client.$error"
          :is-required="true"
        >
          <template v-if="$v.client.$error && !$v.client.required">
            {{ $t('errors.required') }}
          </template>
        </ui-dropdown>
      </ui-wizard-step>
      <ui-wizard-step :title="$t('locations.create.step.location')" :before-change="checkLocator">
        <div class="locations-create__wrapper">
          <ui-dropdown
            id="locations-client"
            class="locations-create__wrapper__input"
            v-model="locationType"
            :options="client.locationTypes"
            :placeholder="$t('locations.create.locationType.placeholder')"
            :dropdown-label="$t('locations.create.locationType.label')"
            :show-label="true"
            :error="$v.locationType.$error"
            open-direction="below"
            :is-required="true"
          >
            <template v-slot:helper v-if="$v.locationType.$error && !$v.locationType.required">
              {{ $t('errors.required') }}
            </template>
          </ui-dropdown>
          <ui-input
            class="locations-create__wrapper__input"
            v-model.trim="externalId"
            :label="$t('locations.create.externalId')"
            id="locations-externalId"
            :error="$v.externalId.$error"
            @input="delayTouch($v.externalId)"
          >
            <template v-if="$v.externalId.$error && !$v.externalId.isUnique">
              {{ $t('errors.unique') }}
            </template>
          </ui-input>
          <ui-input
            class="locations-create__wrapper__input"
            v-model.trim="name"
            :label="$t('locations.create.name')"
            id="locations-name"
            :error="$v.name.$error"
            :is-required="true"
          >
            <template v-if="$v.name.$error && !$v.name.required">
              {{ $t('errors.required') }}
            </template>
          </ui-input>
          <div class="locations-create__wrapper__address">
            <ui-input
              class="locations-create__wrapper__address__input locations-create__wrapper__address__input--successive"
              v-model="street1"
              :label="$t('locations.create.street1')"
              id="location-street1"
              :error="$v.street1.$error"
              :is-required="true"
            >
              <template v-if="$v.street1.$error && !$v.street1.required">
                {{ $t('errors.required') }}
              </template>
            </ui-input>
            <ui-input
              class="locations-create__wrapper__address__input"
              v-model="street2"
              :label="$t('locations.create.street2')"
              id="location-street2"
              design="minimalist"
            >
            </ui-input>
          </div>
          <ui-input
            class="locations-create__wrapper__input"
            :is-required="true"
            v-model="postalCode"
            :label="$t('locations.create.postalCode')"
            id="location-postalCode"
          >
          </ui-input>
          <ui-input
            class="locations-create__wrapper__input"
            v-model="city"
            :label="$t('locations.create.city')"
            id="location-city"
            :is-required="true"
            :error="$v.city.$error"
          >
            <template v-if="$v.city.$error && !$v.city.required">
              {{ $t('errors.required') }}
            </template>
          </ui-input>
          <ui-dropdown
            class="locations-create__wrapper__input"
            id="locations-country-code"
            v-model="countryCode"
            :options="countries"
            :is-required="true"
            label="name"
            track-by="countryCode"
            :placeholder="$t('locations.create.countryCode.placeholder')"
            :dropdown-label="$t('locations.create.countryCode.label')"
            :show-label="true"
            :error="$v.countryCode.$error"
            open-direction="above"
          >
            <template v-slot:helper v-if="$v.countryCode.$error && !$v.countryCode.required">
              {{ $t('errors.required') }}
            </template>
          </ui-dropdown>
          <ui-input
            class="locations-create__wrapper__input"
            v-model="phone"
            :label="$t('locations.create.phone')"
            id="location-phone"
          />
        </div>
      </ui-wizard-step>

      <ui-wizard-step :title="$t('locations.create.step.geo')" :before-change="checkClient">
        <div class="locations-create__grid">
          <screen-block
            :title="$t('locations.create.internationalPhone')"
            icon="phone"
            :has-data="internationalPhone !== ''"
            :data="internationalPhone"
            ratio="1-2"
            :is-loading="isInternationalPhoneUpdate"
            @click="editField('modal-simple-field')"
          />

          <screen-block
            class="locations-create__grid__coordinates"
            :title="$t('locations.create.gps')"
            icon="geoloc"
            :has-data="
              typeof currentCoordinates.latitude === 'number' && typeof currentCoordinates.longitude === 'number'
            "
            :is-required="true"
            ratio="1-2"
            :is-loading="isCoordinatesUpdate"
            @click="editField('modal-coordinates')"
          >
            <template v-slot:data>
              <div class="locations-create__grid__coordinates__section">
                <div class="locations-create__grid__coordinates__section__label">
                  {{ $t('common.label.latitude') }}
                </div>
                <div class="locations-create__grid__coordinates__section__value">
                  {{ currentCoordinates.latitude }}
                </div>
              </div>
              <div class="locations-create__grid__coordinates__section">
                <div class="locations-create__grid__coordinates__section__label">
                  {{ $t('common.label.longitude') }}
                </div>
                <div class="locations-create__grid__coordinates__section__value">
                  {{ currentCoordinates.longitude }}
                </div>
              </div>
            </template>
          </screen-block>

          <screen-block
            :title="$t('locations.create.subDivisions')"
            icon="world"
            :is-required="true"
            :has-data="
              typeof currentLocation.subDivision1 === 'string' &&
              currentLocation.subDivision1 !== '' &&
              subDivisions !== null
            "
            ratio="1-1"
            :is-loading="isSubDivisionUpdate"
            @click="editField('modal-sub-divisions')"
          >
            <template v-slot:data>
              <div v-if="currentLocation.subDivision1">
                {{ mapSubDivision(currentLocation.subDivision1, 'level1') }}
              </div>
              <div v-if="currentLocation.subDivision2">
                {{ mapSubDivision(currentLocation.subDivision2, 'level2') }}
              </div>
              <div v-if="currentLocation.subDivision3">
                {{ mapSubDivision(currentLocation.subDivision3, 'level3') }}
              </div>
            </template>
          </screen-block>
        </div>
      </ui-wizard-step>
      <ui-wizard-step :title="$t('locations.create.step.features')" :before-change="() => true">
        <div class="locations-create__featuresSelection">
          <feature-list-selection
            displaymode="grid"
            :features="currentClientFeatureList"
            :activeList="featuresActiveList"
            :exceptions="featuresExceptionsList"
            :parameters="{ mode: 'location' }"
            @inputDataChanged="onFeaturesInputDataChanged"
          />
        </div>
      </ui-wizard-step>
      <ui-wizard-step :title="$t('locations.create.step.summary')">
        <div class="locations-create__grid">
          <screen-block
            :title="$t('locations.create.label.client')"
            icon="business"
            :has-data="client !== null && typeof client.name === 'string'"
            :data="client.name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.externalId')"
            icon="business"
            :has-data="externalId !== ''"
            :data="externalId"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.location')"
            icon="location"
            :has-data="name !== ''"
            :data="name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.address')"
            icon="pin"
            :has-data="street1 !== ''"
            :data="name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <div>
                {{ street1 }}
              </div>
              <div v-if="street2">
                {{ street2 }}
              </div>
              <div>
                <span v-if="postalCode">{{ postalCode }}</span>
                {{ city }}
              </div>
              <div v-if="countryCode">
                {{ countryCode.name }}
              </div>
            </template>
          </screen-block>
          <screen-block
            class="locations-create__gps"
            :title="$t('locations.create.label.gps')"
            icon="geoloc"
            :has-data="
              typeof currentCoordinates.latitude === 'number' && typeof currentCoordinates.longitude === 'number'
            "
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <div class="locations-create__gps__coordinates__section">
                <div class="locations-create__gps__coordinates__section__label">
                  {{ $t('common.label.latitude') }}
                </div>
                <div class="locations-create__gps__coordinates__section__value">
                  {{ currentCoordinates.latitude }}
                </div>
              </div>
              <div class="locations-create__gps__coordinates__section">
                <div class="locations-create__gps__coordinates__section__label">
                  {{ $t('common.label.longitude') }}
                </div>
                <div class="locations-create__gps__coordinates__section__value">
                  {{ currentCoordinates.longitude }}
                </div>
              </div>
            </template>
          </screen-block>
          <screen-block
            :title="$t('locations.create.label.subDivisions')"
            icon="world"
            :has-data="
              typeof currentLocation.subDivision1 === 'string' &&
              currentLocation.subDivision1 !== '' &&
              subDivisions !== null
            "
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <div v-if="currentLocation.subDivision1">
                {{ mapSubDivision(currentLocation.subDivision1, 'level1') }}
              </div>
              <div v-if="currentLocation.subDivision2">
                {{ mapSubDivision(currentLocation.subDivision2, 'level2') }}
              </div>
              <div v-if="currentLocation.subDivision3">
                {{ mapSubDivision(currentLocation.subDivision3, 'level3') }}
              </div>
            </template>
          </screen-block>
          <screen-block
            :title="$t('locations.create.label.phone')"
            icon="phone"
            :has-data="phone !== ''"
            :data="phone"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.internationalPhone')"
            icon="phone"
            :has-data="internationalPhone !== ''"
            :data="internationalPhone"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('locations.create.label.locationType')"
            icon="attribut"
            :has-data="locationType !== null && typeof locationType === 'string'"
            :data="locationType"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <feature-list-card
            class="locations-create__grid__features"
            :featureListDetails="featuresActiveListDetails"
            :featureList="
              Object.keys(featuresActiveList)
                .map(key => ({
                  tag: key,
                  list: featuresActiveList[key],
                }))
                .filter(item => item.list != null && item.list.length > 0)
            "
          />
        </div>
      </ui-wizard-step>
      <modal-simple-field
        v-if="isEditing && editingModal === 'modal-simple-field' && !isInternationalPhoneUpdate"
        :title="modalTitle"
        :is-updating="isInternationalPhoneUpdate"
        :data="internationalPhone"
        label="internationalPhone"
        obj-key="internationalPhone"
        @save="saveField($event, 'modal-simple-field')"
        @closed="closedModal"
      />
      <modal-coordinates
        v-if="isEditing && editingModal === 'modal-coordinates' && !isCoordinatesUpdate"
        :title="modalTitle"
        :is-updating="isCoordinatesUpdate"
        :data="currentCoordinates"
        @save="saveField($event, 'modal-coordinates')"
        @closed="closedModal"
      />
      <modal-sub-divisions
        :data="currentLocation"
        :sub-divisions="subDivisions"
        v-if="isEditing && editingModal === 'modal-sub-divisions' && !isSubDivisionUpdate"
        :title="modalTitle"
        :is-updating="isSubDivisionUpdate"
        :current-coordinates="currentCoordinates"
        @save="saveField($event, 'modal-sub-divisions')"
        @closed="closedModal"
      />
    </ui-wizard>
  </div>
</template>

<script>
import { getInternationalPhone, geocode, getGeonames } from '@/services/tool.service'
import UiWizard from '@/components/UI/Wizard/Wizard.vue'
import UiWizardStep from '@/components/UI/Wizard/Step.vue'
import UiInput from '@/components/UI/Input.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import ModalCoordinates from '@/components/Modal/Coordinates.vue'
import ModalSubDivisions from '@/components/Modal/SubDivisions.vue'
import FeatureListSelection from '@/components/FeatureList/FeatureListSelection.vue'
import FeatureListCard from '@/components/FeatureList/FeatureListCard.vue'
import { notif } from '@/mixins/notification.mixin'
import { mapActions, mapState } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { locationCreateGeoip } from '@/services/location.service'

const touchMap = new WeakMap()

export default {
  name: 'LocationsCreate',
  components: {
    UiWizard,
    UiWizardStep,
    UiInput,
    UiDropdown,
    ScreenBlock,
    ModalCoordinates,
    ModalSubDivisions,
    ModalSimpleField,
    FeatureListSelection,
    FeatureListCard,
  },
  mixins: [notif],
  data() {
    return {
      client: null,
      locationType: null,
      name: '',
      externalId: '',
      street1: '',
      street2: '',
      postalCode: '',
      city: '',
      countryCode: null,
      phone: '',
      isSaving: false,
      location: null,
      wizardIndex: 0,
      isEditing: false,
      editingModal: '',
      internationalPhone: '',
      currentCoordinates: {},
      currentLocation: {},
      isInternationalPhoneUpdate: true,
      isCoordinatesUpdate: true,
      isSubDivisionUpdate: true,
      featuresActiveList: [],
      featuresExceptionsList: {},
      featuresActiveListDetails: [],
    }
  },
  async created() {
    this.client = this.brands[0]
    const countriesClient = await locationCreateGeoip()
    this.countryCode = this.countries.find(countrie => countrie.countryCode === countriesClient.country) ?? null
  },
  watch: {
    client: {
      deep: true,
      handler() {
        this.locationType = this.client.locationTypes[0]
        this.getClientFeaturesList({ clientId: this.client.id })
      },
    },
    wizardIndex: {
      handler() {
        if (this.wizardIndex === 2) {
          this.initializeGeolocalisation()
        }
      },
    },
  },
  computed: {
    ...mapState({
      brands: state => state.user.brands,
      currLocale: state => state.user.currentUser.locale,
      countries: state => state.user.countries,
      subDivisions: state => state.tool.subDivisions,
      currentClientFeatureList: state => state.client.currentClientFeatureList,
    }),
    modalTitle() {
      return this.$t('common.modal.title.location', {
        location: this.name,
      })
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('frontOffices.list.breadcrumb'),
          route: {
            name: 'Locations',
          },
        },
        {
          label: this.$t('locations.create.breadcrumb'),
          route: {
            name: 'LocationCreate',
          },
        },
      ]
    },
  },
  methods: {
    itemsExist() {
      let titlesList = []
      let itemsList = this.currentClientFeatureList
        .map(itemList => itemList.items?.length > 0 && itemList.items)
        .filter(item => item)
      itemsList = [].concat.apply([], itemsList)
      itemsList.forEach(item => titlesList.push(item.title))

      return false
    },
    ...mapActions({
      createLocation: 'location/createLocation',
      updateCurrentClient: 'user/updateCurrentClient',
      getSubDivisions: 'tool/getSubDivisions',
      getClientFeaturesList: 'client/getClientFeaturesList',
      getUniqueExternalId: 'location/getUniqueExternalId',
    }),
    editField(modalLabel) {
      this.editingModal = modalLabel
      this.isEditing = true
      this.$nextTick(() => {
        this.$modal.show(modalLabel)
      })
    },
    saveField(params, modalLabel) {
      this.$modal.hide(modalLabel)
      if (modalLabel === 'modal-sub-divisions') {
        this.currentLocation = params.objKey
      } else if (modalLabel === 'modal-coordinates') {
        this.currentCoordinates = params.objKey
      } else if (modalLabel === 'modal-simple-field') {
        this.internationalPhone = params.objKey['internationalPhone']
      }
      this.closedModal()
    },
    closedModal() {
      this.isEditing = false
      this.editingModal = ''
    },
    async loadInternationalPhone() {
      if (this.phone) {
        const data = [
          {
            phone: this.phone,
            country: this.countryCode.countryCode,
          },
        ]
        try {
          const { data: internationalPhoneResult } = await getInternationalPhone(data)
          this.internationalPhone = internationalPhoneResult[0].e164
        } catch (err) {
          this.$log.error(err)
        }
      }
      this.isInternationalPhoneUpdate = false
    },
    async loadCoordinates() {
      const fullAddress = this.street1 + ',' + this.postalCode + ',' + this.city + ',' + this.countryCode.countryCode
      try {
        const { data: geoCodeResult } = await geocode(fullAddress)
        this.currentCoordinates = {
          city: this.city,
          countryCode: this.countryCode.countryCode,
          postalCode: this.postalCode,
          street1: this.street1,
          street2: this.street2,
          latitude: geoCodeResult.lat,
          longitude: geoCodeResult.lng,
        }
      } catch (err) {
        this.$log.error(err)
      }
      this.isCoordinatesUpdate = false
    },
    async loadSubdivisions() {
      const data = {
        countryCode: this.countryCode.countryCode,
        latitude: this.currentCoordinates.latitude,
        longitude: this.currentCoordinates.longitude,
      }
      try {
        const { data: geoResult } = await getGeonames(data)
        await this.getSubDivisions({ locale: this.currLocale, countryCode: this.countryCode.countryCode })
        this.currentLocation = geoResult
      } catch (err) {
        this.$log.error(err)
      }
      this.isSubDivisionUpdate = false
    },
    async initializeGeolocalisation() {
      this.isInternationalPhoneUpdate = true
      this.isSubDivisionUpdate = true
      this.isCoordinatesUpdate = true
      await Promise.all([this.loadCoordinates(), this.loadInternationalPhone()])
      await this.loadSubdivisions()
    },
    onBack() {
      this.$router.push({ name: 'Locations' })
    },
    onComplete() {
      this.isSaving = true
      this.createLocation({
        name: this.name,
        street1: this.street1,
        street2: this.street2,
        postalCode: this.postalCode,
        city: this.city,
        countryCode: this.countryCode.countryCode,
        phone: this.phone,
        clientId: this.client.id,
        locationType: this.locationType,
        status: '1',
        latitude: this.currentCoordinates.latitude,
        longitude: this.currentCoordinates.longitude,
        externalId: this.externalId,
        subDivision1: this.currentLocation.subDivision1,
        subDivision2: this.currentLocation.subDivision2,
        subDivision3: this.currentLocation.subDivision3,
        internationalPhone: this.internationalPhone,
        featureList: this.featuresActiveList,
      })
        .then(location => {
          this.location = location
          return this.updateCurrentClient(this.client)
        })
        .then(() => {
          this.notificationSuccess('location', this.location.name, 'create')
          this.$router.push({ name: 'Location', params: { id: this.location.id } })
          this.isSaving = false
        })
        .catch(() => {
          this.isSaving = false
          this.notificationError()
        })
    },
    updateIndex(index) {
      this.wizardIndex = index
    },
    checkClient() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
    checkLocator() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
    onFeaturesInputDataChanged({ activeList, exceptions, activeListDetails }) {
      this.featuresActiveList = activeList
      this.featuresActiveListDetails = activeListDetails
      this.featuresExceptionsList = exceptions
    },
    mapSubDivision(division, level) {
      let subDivision = null
      if (level === 'level1') {
        subDivision = this.subDivisions[level].find(sub => sub.code === division)
      } else if (level === 'level2') {
        subDivision = this.subDivisions[level].find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision1
        )
      } else if (level === 'level3') {
        subDivision = this.subDivisions[level].find(
          sub => sub.code === division && sub.code_up === this.currentLocation.subDivision2
        )
      }
      return subDivision ? subDivision.name : this.$t('errors.subDivisions')
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 300))
    },
  },
  validations() {
    return {
      client: {
        required,
      },
      name: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
      externalId: {
        async isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (value === '') {
            return true
          }

          // simulate async call, fail for all logins with even length
          const isUnique = await this.getUniqueExternalId(value)
          return !isUnique
        },
      },
      street1: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
      city: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
      countryCode: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
      locationType: {
        required: requiredIf(() => {
          return this.wizardIndex === 1
        }),
      },
      currentCoordinates: {
        required: requiredIf(() => {
          return this.wizardIndex === 2
        }),
      },
      currentLocation: {
        subDivision1: {
          required: requiredIf(() => {
            return this.wizardIndex === 2 && this.subDivisions?.level1.length > 0
          }),
        },
        subDivision2: {
          required: requiredIf(() => {
            return this.wizardIndex === 2 && this.subDivisions?.level2.length > 0
          }),
        },
        subDivision3: {
          required: requiredIf(() => {
            return this.wizardIndex === 2 && this.subDivisions?.level3.length > 0
          }),
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.locations-create {
  &__wrapper {
    @media (min-width: $screen-sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__input,
    &__address {
      margin-bottom: $gutter-mobile;

      @media (min-width: $screen-sm) {
        width: calc(100% / 2 - #{$gutter-tablet});
      }

      @media (min-width: $screen-sm) {
        margin-bottom: $gutter-tablet;
      }

      &__input {
        &--successive {
          margin-bottom: 8px;
        }
      }
    }
  }

  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &--successive {
      margin-bottom: 8px;
    }
  }

  &__featuresSelection {
    /* stylelint-disable selector-pseudo-element-no-unknown */
    ::v-deep .feature-list-selection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__section {
        min-width: 40%;
      }
    }
    /* stylelint-enable selector-pseudo-element-no-unknown */
  }

  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: (-$gutter-mobile / 2);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet / 2);
    }

    @media (min-width: $screen-xl) {
      margin: (-$gutter-desktop / 2);
    }

    &__coordinates {
      &__section {
        display: flex;
        justify-content: space-between;

        &__label {
          font-weight: 500;
        }
      }
    }
  }

  &__gps {
    &__coordinates {
      &__section {
        display: flex;
        justify-content: space-between;

        &__label {
          font-weight: 500;
        }
      }
    }
  }

  &__features {
    margin: 0 auto;
  }
}
</style>
